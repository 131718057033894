<template>
  <div class="postcodeBanner" id="form-section">
    <b-container
      fluid="lg"
      class="d-flex firstBanner align-items-center overflow-hidden mb-0"
    >
      <b-row class="my-auto mx-md-0 mx-sm-4 mx-0 w-100 h-100">
        <b-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-content-center pt-md-0 pt-4"
        >
          <p class="m-0 first-question__title">
            <span class="first-question__title-bold"
              >We zoeken u om het <br />
              revolutionaire alarmsysteem <br />
              te ontdekken en ervaren!</span
            >
          </p>
          <p class="first-question__description mb-0 mt-4">
            Check hier of het installeren van dit innovatieve alarmsysteem wordt
            aanbevolen voor uw woning.
          </p>
          <p
            class="position-relative first-question__text font-weight-bold mb-1 pt-md-5 pt-3"
          >
            Vul hier uw postcode in:
            <!-- <img :src="arrow" class="arrowImg" /> -->
            <!-- <img
              class="offerImage"
              src="../../assets/verisurehouse/fourtyoff.png"
            /> -->
          </p>

          <div class="bg-white py-3 px-3 pl-2 postcodeBanner__postcodeDiv">
            <div class="postcodeBanner__postcode">
              <b-form @submit.prevent="onSubmit" class="row">
                <b-form-input
                  class="postcodeBanner__zipInput ml-3 px-0 my-auto text-center"
                  v-model="zip"
                  :state="zipValidation"
                  placeholder="2000AB"
                  maxLength="7"
                  required
                ></b-form-input>
                <b-button
                  pill
                  block
                  type="submit"
                  variant="primary"
                  class="py-3 my-2 d-none d-md-flex position-relative primaryred-isolatie-btn align-items-center postcodeBanner__zipInput--btn"
                >
                  Check mijn postcode
                  <!-- <img :src="btn_arrow" width="25px"/> -->
                  <svg
                    class="px-2"
                    width="35"
                    height="12"
                    viewBox="0 0 22 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.2375 5.47064C21.2373 5.47037 21.2371 5.47005 21.2368 5.46977L16.9439 0.69228C16.6223 0.334382 16.1021 0.335714 15.782 0.695404C15.4619 1.05505 15.4631 1.63675 15.7847 1.9947L18.667 5.20223H1.2678C0.814046 5.20223 0.446228 5.61356 0.446228 6.12098C0.446228 6.62841 0.814046 7.03973 1.2678 7.03973H18.6669L15.7848 10.2473C15.4632 10.6052 15.4619 11.1869 15.782 11.5466C16.1021 11.9063 16.6224 11.9075 16.9439 11.5497L21.2368 6.77219C21.2371 6.77191 21.2373 6.77159 21.2376 6.77132C21.5593 6.41218 21.5583 5.82859 21.2375 5.47064Z"
                      fill="white"
                    />
                  </svg>
                </b-button>
              </b-form>
            </div>
            <b-button
              pill
              block
              @click="onSubmit"
              variant="primary"
              class="py-3 my-2 d-flex d-md-none position-relative primaryred-isolatie-btn align-items-center postcodeBanner__zipInput--btn"
            >
              Controleer
              <!-- <img :src="btn_arrow" width="25px"/> -->
              <svg
                class="px-2"
                width="35"
                height="12"
                viewBox="0 0 22 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21.2375 5.47064C21.2373 5.47037 21.2371 5.47005 21.2368 5.46977L16.9439 0.69228C16.6223 0.334382 16.1021 0.335714 15.782 0.695404C15.4619 1.05505 15.4631 1.63675 15.7847 1.9947L18.667 5.20223H1.2678C0.814046 5.20223 0.446228 5.61356 0.446228 6.12098C0.446228 6.62841 0.814046 7.03973 1.2678 7.03973H18.6669L15.7848 10.2473C15.4632 10.6052 15.4619 11.1869 15.782 11.5466C16.1021 11.9063 16.6224 11.9075 16.9439 11.5497L21.2368 6.77219C21.2371 6.77191 21.2373 6.77159 21.2376 6.77132C21.5593 6.41218 21.5583 5.82859 21.2375 5.47064Z"
                  fill="white"
                />
              </svg>
            </b-button>
          </div>

          <small class="text-danger ml-4" v-if="isInValid"
            >Postcode niet correct</small
          >
        </b-col>
        <b-col class="d-flex justify-content-center align-items-center">
          <div
            class="text-center mt-3 d-flex justify-content-center align-items-center"
          >
            <img :src="phone" width="110%" height="110%" />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import arrow from "@/assets/postcoode/header_arrow.png";

//import btn_arrow from "@/assets/rightArrowbtn.png";
import arrowImg from "@/assets/postcoode/arrowB.png";
import phone from "@/assets/newBeveiligingcheck.png";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "FirstBanner",

  data() {
    return {
      zip: "",
      arrow,
      //btn_arrow,
      phone,
      arrowImg,
      isInValid: false,
      validationCheck: true,
    };
  },
  methods: {
    ...mapActions(["setPostcode", "setActiveUser", "getActiveUsers"]),

    onSubmit() {
      if (this.validationCheck) {
        this.$emit("nextPage");
        this.setPostcode(this.zip);
      } else {
        this.isInValid = true;
      }
    },
  },
  mounted() {
    this.setActiveUser();
    this.getActiveUsers();
  },
  computed: {
    ...mapGetters(["activeUsers"]),
    zipValidation() {
      this.isInValid = false;
      if (this.zip == "") {
        this.validationCheck = false;
        return null;
      }
      let zipRegex = /^([0-9]{4}\s?[a-zA-Z]{2})$/;
      if (this.zip.match(zipRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.firstBanner {
  @media screen and (max-width: 1600px) and (min-width: 765.99px) {
    height: 500px;
  }
}

.arrowImg {
  position: absolute;
  right: 25%;
  width: 30px;
  @media (max-width: 991.98px) {
    display: none;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1040px !important;
  }
}

.offerImage {
  position: absolute;
  right: -8%;
  height: 180%;
  @media (min-width: 768px) and (max-width: 849.98px) {
    right: -5%;
    top: 5px;
  }
  @media only screen and (max-width: 767.98px) {
    right: 0%;
    bottom: -70%;
  }
}
.postcodeBanner {
  font-family: "Fira Sans", sans-serif;
  // height: 580px;
  &__postcode {
    background-color: #f1f1f1;
    border-radius: 50px;
  }
  &__smallHeader {
    color: #ff0032;
    // text-transform: uppercase;
    font-weight: bold;
  }
  &__arrowImg {
    position: absolute;
    width: 70px;
    top: -50px;
    @media (max-width: 991.98px) {
      right: 50px;
    }
    @media (max-width: 767.98px) {
      display: none;
    }
  }
  &__postcodeDiv {
    box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    width: 80%;
    @media (min-width: 768px) and (max-width: 992.98px) {
      width: 75%;
    }
    .form-control:focus {
      color: #495057;
      background-color: #fff;
      border-color: rgba(128, 189, 255, 0) !important;
      outline: 0;
      box-shadow: none !important;
    }
    @media only screen and (max-width: 767.98px) {
      width: 100%;
    }
  }
  &__zipInput {
    background-color: #f1f1f1;
    border: none;
    border-radius: 12px;
    width: 27%;
    font-size: 16px;
    &:hover,
    &:focus,
    &:active {
      background-color: #f1f1f1 !important;
    }
    @media (min-width: 640px) and (max-width: 767.98px) {
      width: 31.5%;
    }
    @media (min-width: 500px) and(max-width: 639.98px) {
      width: 29%;
    }
    @media (max-width: 767.98px) {
      width: 50%;
    }
    // &::placeholder,
    // &::-webkit-input-placeholder,
    // &:-moz-placeholder,
    // &::-moz-placeholder,
    // &:-ms-input-placeholder {
    //   font-size: 14px;
    // }
    &--btn {
      width: 63%;
      font-size: 20px;
      justify-content: end;
      font-weight: bold;
      @media (max-width: 1068.99px) {
        width: 61%;
        font-size: 12px;
      }
      @media (max-width: 767.98px) {
        justify-content: center;
        font-size: 20px;
        width: 100%;
      }
      // @media (max-width: 449.98px) {
      //   justify-content: center;

      // }
      img {
        margin-left: 10px;

        @media (min-width: 450px) and(max-width: 767.98px) {
          position: absolute;
          right: 20px;
        }
        @media (max-width: 349.98px) {
          margin-left: 5px;
        }
      }
    }
  }
}
.bottomArrow {
  margin-bottom: -110px;
  width: 11px;
  @media only screen and (max-width: 767.98px) {
    margin-top: -40px;
  }
}
.w-65 {
  width: 65%;
  @media only screen and (max-width: 767.98px) {
    width: 100%;
  }
}
.primaryred-isolatie-btn {
  background: #ed0025 !important;
  border-color: #ed0025 !important;
  //text-transform: uppercase;
  &:hover {
    background: #ed0025 !important;
  }
}
.first-question {
  max-width: 1040px;
  font-family: "Fira Sans", sans-serif;
  position: relative;
  &__arrow-down {
    width: 15px;
  }
  &__text {
    color: #000000;
  }
  &__title {
    color: #000000;
    // line-height: 45px;
    font-size: 35px;
    line-height: 38px;

    text-align: start;
    &-bold {
      font-weight: 700;
    }
  }
  &__description {
    // font-size: 30px;

    font-size: 24px;
    color: #000000;
    font-weight: 400;
    text-align: start;
    line-height: 35px;

    &-bold {
      font-weight: 800;
    }
  }

  &__gratis {
    border-radius: 10px;
    background-color: #ff0033;
    position: absolute;
    right: 0;
    // top: -9%;
    top: -10%;
    // padding: 13px 35px;
    padding: 13px 35px 13px 25px;
    &-text {
      // font-size: 20px;
      font-size: 18px;
      letter-spacing: 1px;
      color: #ffffff;
      font-weight: 600;
      text-align: start;
    }
    &-img {
      position: absolute;
      // right: -3%;
      // top: -14.5%;
      // width: 7%;
      right: -2.8%;
      top: -16%;
      width: 5.5%;
    }
  }
  &__sticker {
    position: absolute !important;
    width: 200px !important;
    height: 200px !important;
    left: -3.8%;
    top: -27%;
  }

  @media (max-width: 1023.99px) {
    &__title {
      font-size: 25px;
      line-height: 30px;
    }
    &__description {
      line-height: 30px;
      font-size: 20px;
    }
  }

  @media (max-width: 767.99px) {
    &__title {
      font-size: 26px;
      line-height: 25px;
    }
    &__description {
      line-height: 25px;
      font-size: 18px;
    }
  }

  @media (max-width: 380.99px) {
    &__title {
      font-size: 25px;
      line-height: 28px;
    }
    &__description {
      line-height: 25px;
      font-size: 19px;
    }
  }
}
</style>
