var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{staticClass:"text-center"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"7"}},[_c('div',{staticClass:"question-card my-5 d-flex flex-column"},[_c('p',{staticClass:"text-justify question-card__text"},[_vm._v(" Vraag "+_vm._s(_vm.item?.question2)+" "+_vm._s(_vm.item?.question3)+" van "+_vm._s(_vm.max)+" ")]),_c('p',{staticClass:"question-card__question"},[_vm._v(" "+_vm._s(_vm.item?.question)+" ")]),_c('div',{},[_vm._l((_vm.item.items),function(item,index){return _c('div',{key:index,staticClass:"mt-3",on:{"click":function($event){return _vm.selectItem(item.label)}}},[_c('b-form-radio',{staticClass:"radio-background d-flex pl-5",attrs:{"name":'radio-group-' + index,"switch":"","size":"sm","value":item.label},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('span',{staticClass:"question-card__label",class:{ 'font-weight-bold': _vm.isSelected(item.label) }},[_vm._v(_vm._s(item.label))])])],1)}),(_vm.currentQuestion === 2)?_c('div',{class:[
              'd-flex',
              _vm.isError ? 'mt-3' : 'mt-md-5 mt-4',
              'flex-column',
              'align-items-end',
            ]},[(_vm.isError)?_c('span',{staticClass:"mb-3 invalid-text"},[_vm._v("Selecteer een optie")]):_vm._e(),_c('b-button',{staticClass:"question-card__firstBtn px-md-4 px-3 py-3 d-flex align-items-center",attrs:{"size":"md"},on:{"click":_vm.onNextClick}},[_vm._v(" Volgende vraag "),_c('img',{staticClass:"ml-3",attrs:{"src":require("../../assets/rightArrowbtn.png")}})])],1):_vm._e(),(_vm.currentQuestion === 3)?_c('div',{class:[
              'd-flex',
              _vm.isError ? 'mt-3' : 'mt-md-5 mt-4',
              'flex-column',
              'align-items-end',
            ]},[(_vm.isError)?_c('span',{staticClass:"mb-3 invalid-text"},[_vm._v("Selecteer een optie")]):_vm._e(),_c('b-button',{staticClass:"question-card__firstBtn px-md-4 px-3 py-3 d-flex align-items-center",attrs:{"size":"md"},on:{"click":_vm.onNextClick}},[_vm._v(" Ga verder "),_c('img',{staticClass:"ml-3",attrs:{"src":require("../../assets/rightArrowbtn.png")}})])],1):_vm._e()],2)])]),_c('b-col',{staticClass:"d-flex justify-content-center pt-2 align-items-center",attrs:{"cols":"12","md":"5"}},[_c('img',{staticClass:"ml-md-5 rightImage",attrs:{"src":require("../../assets/newBeveiligingcheck.png"),"alt":""}})])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }